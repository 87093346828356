<template>
  <div>
    <error-alert
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="refInventoryCreate"
      >
        <div>
          <inventory-s-form-body v-if="inventoryISSerialized" />
          <inventory-n-s-form-body v-else />
        </div>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="clear"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                :disabled="isCreateForm ? false : !isFormChanged"
                class="saveBtn font-medium-1 font-weight-bolder"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>
// Libs
import { ValidationObserver } from 'vee-validate'
import { scrollToError } from '@core/utils/utils'
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import config from '@/views/main/inventory/inventoryConfig'

// Components
import {
  BForm, BButton,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import InventoryNSFormBody from './InventoryNSFormBody.vue'
import InventorySFormBody from './InventorySFormBody.vue'

export default {
  name: 'InventoryForm',
  components: {
    BForm,
    BButton,
    ErrorAlert,
    ValidationObserver,
    InventoryNSFormBody,
    InventorySFormBody,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      onSubmit: false,
    }
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventoryForm
    },
    inventoryISSerialized() {
      return this.$store.state[this.MODULE_NAME].inventorySerializedAndNonSerializedVal
    },
    getStockProduct() {
      return this.$store.state[this.MODULE_NAME].inventory
    },
    inventoryClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].inventoryFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.inventory) !== this.inventoryClone
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
  },
  methods: {
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.refInventoryCreate.validate().then(success => {
        if (success) {
          const data = this.inventory

          const mappedData = {
            product: this.getStockProduct.id,
          }

          mappedData.detail = {
            customer_id: data.customer_id.id,
            date_of_purchase: data.date_of_purchase,
            purchase_order_number: data.purchase_order_number ?? null,
            invoice_number: data.invoice_number ?? null,
          }

          if (mappedData.detail.date_of_purchase) {
            const date = new Date(mappedData.detail.date_of_purchase)
            mappedData.detail.date_of_purchase = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
          } else {
            mappedData.detail.date_of_purchase = ''
          }

          if (this.inventoryISSerialized) {
            mappedData.items = data.amount_to_add.map(item => ({
              location: data.location,
              available_from: data.available_start_date,
              cost: data.cost,
              name: item.name,
              serial_number: item.serial_number ?? null,
              sale_price: item.sale_price,
              tags: data.tags ?? [],
              comments: data.comments,
              condition_id: item.condition_id?.id,
              active: data.active ?? false,
              inspected: data.inspected ?? false,
              tested: data.tested ?? false,
              allowed_to_sell: item.allowed_to_sell ?? false,
              amount: data.amount_to_add.length,
            }))

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < mappedData.items.length; i++) {
              if (mappedData.items[i].available_from) {
                const date = new Date(mappedData.items[i].available_from)
                mappedData.items[i].available_from = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
              } else {
                mappedData.items[i].available_from = ''
              }
            }
          } else {
            mappedData.items = [
              {
                location: data.location,
                available_from: data.available_start_date,
                cost: data.cost,
                name: data.name,
                serial_number: data.serial_number ?? null,
                sale_price: data.sale_price,
                tags: data.tags ?? [],
                comments: data.comments,
                active: data.active ?? false,
                inspected: data.inspected ?? false,
                tested: data.tested ?? false,
                allowed_to_sell: data.allowed_to_sell ?? false,
                amount: data.amount_to_add.length,
              },
            ]
            if (mappedData.items[0].available_from) {
              const date = new Date(mappedData.items[0].available_from)
              mappedData.items[0].available_from = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
            } else {
              mappedData.items[0].available_from = ''
            }
          }

          this.sendNotification(this, mappedData, `${this.MODULE_NAME}/create`)
            .then(() => {
              this.clear()
            }).catch(err => {
              this.error = err.response?.data
            })
        } else {
          scrollToError(this, this.$refs.refInventoryCreate)
        }
      })
    },
    clear() {
      this.$refs.refInventoryCreate.reset()
      this.$router.push({ name: 'home-inventory-view', query: { location: `${this.warehouse.id}` } })
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const MODULE_NAME_CLONE = 'cloneData'

    const { fields } = config()
    return {
      fields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
