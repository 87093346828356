<template>
  <div>
    <div
      v-for="(amount, index) in amounts"
      :key="index"
      class="bg-white"
      style="border-radius: 5px; padding: 12px; margin-bottom: 10px"
    >
      <b-row>
        <b-col
          cols="6"
        >
          <!-- <component
            :is="fieldsNonSerializedAmount[field].type"
            v-for="field in ['name']"
            :key="field"
            v-model="amounts[index][field]"
            v-bind="getProps(field)"
            :is-required-field="isRequired"
          /> -->
          <component
            :is="fieldsNonSerializedAmount[field].type"
            v-for="field in ['serial_number']"
            :key="field"
            v-model="amounts[index][field]"
            v-bind="getProps(field)"
            :is-required-field="getSellActive(index)"
          />
          <!--          <component-->
          <!--            :is="fieldsNonSerializedAmount[field].type"-->
          <!--            v-for="field in ['sale_price']"-->
          <!--            :key="field"-->
          <!--            v-model="amounts[index][field]"-->
          <!--            v-bind="getProps(field)"-->
          <!--            :is-required-field="getSellActive(index)"-->
          <!--          />-->
        </b-col>
        <b-col>
          <component
            :is="fieldsNonSerializedAmount['condition_id'].type"
            v-model="amounts[index]['condition_id']"
            v-bind="getProps('condition_id')"
          />
          <span class="font-weight-bolder ml-1"> {{ $t('Sales') }} </span>
          <component
            :is="fieldsNonSerializedAmount[field].type"
            v-for="field in ['allowed_to_sell']"
            :key="field"
            v-model="amounts[index][field]"
            :is-editable="isProductDetailCanBeSold"
            v-bind="getPropsLocationFormCheckbox(field, index)"
            class="mb-1 custom--inventory-checkbox"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import config from '@/views/main/inventory/inventoryConfig'

// Components
import { BRow, BCol } from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import { ref } from '@vue/composition-api'
import LSelectFilter from '@/views/components/filters/formComponents/LSelectFilter.vue'

export default {
  name: 'RenderAmounts',
  components: {
    LSelectFilter,
    Treeselect,
    BCol,
    BRow,
  },
  computed: {
    amounts() {
      return this.$store.state[this.MODULE_NAME].inventoryForm.amount_to_add
    },
    inventoryWarehouseLocations() {
      return this.$store.state[this.MODULE_NAME].warehouses?.locations ?? []
    },

    getStockProduct() {
      return this.$store.state[this.MODULE_NAME].inventory
    },
    isProductDetailCanBeSold() {
      return !this.getProduct?.detail?.can_be_sold
    },
    getProduct() {
      return this.$store.state[this.MODULE_NAME].product
    },
  },
  methods: {
    getProps(fieldName) {
      return {
        field: this.fieldsNonSerializedAmount[fieldName],
        name: fieldName,
        class: this.fieldsNonSerializedAmount[fieldName]?.class,
      }
    },
    getSellActive(num) {
      return this.$store.state[this.MODULE_NAME].inventoryForm.amount_to_add[num]?.allowed_to_sell
    },
    hasKey(key) {
      // eslint-disable-next-line no-prototype-builtins
      return this.fieldsNonSerializedAmount.hasOwnProperty(key)
    },
    returnTags(tagsArrName) {
      return this.getStockProduct[tagsArrName]?.map(tag => tag?.label)
    },
    getPropsLocationFormCheckbox(fieldName, idx) {
      return {
        field: this.fieldsNonSerializedAmount[fieldName],
        name: fieldName + idx,
        class: this.fieldsNonSerializedAmount[fieldName]?.class,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const { fieldsNonSerializedAmount } = config()
    const isRequired = ref(false)

    return {
      isRequired,
      MODULE_NAME,
      fieldsNonSerializedAmount,
    }
  },
}
</script>
