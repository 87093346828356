<template>
  <div>
    <label
      for="supplier-name"
      class="font-weight-bolder ml-1"
    >{{ $t('Supplier Name') }}</label>
    <div>
      <validation-provider
        #default="{ errors }"
        name="Supplier name"
        :rules="'required'"
        vid="supplier-name"
        class="w-100"
      >
        <div
          class="w-100 d-flex"
          style="gap: 8px"
        >
          <vue-autosuggest
            id="supplier-name"
            ref="autocomplete"
            v-model="searchQuery"
            :suggestions="suggestions"
            :input-props="getSearchInputProps()"
            class="flex-fill"
            :render-suggestion="renderSuggestion"
            :get-suggestion-value="getSuggestionValue"
            @blur="onBlurHandler()"
            @input="fetchResults"
            @selected="selectHandler"
          />
          <b-button
            class="btn-mini"
            variant="secondary"
            style="min-width: 36px; max-width: 36px; height: 36px"
          >
            <feather-icon
              icon="LSearchIcon"
              size="16"
            />
          </b-button>
        </div>
        <small class="text-danger">{{ errors[0] }}</small>
        <small
          v-if="isInvalidSupplier"
          class="text-danger"
        >{{ $t('Invalid Supplier') }}</small>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import { debounce } from '@core/utils/utils'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { ValidationProvider } from 'vee-validate'

import {
  BButton,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  name: 'InventorySupplierName',
  components: {
    BButton,
    VueAutosuggest,
    ValidationProvider,
  },
  data() {
    return {
      searchQuery: '',
      suggestions: [],
      isInvalidSupplier: false,
    }
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventoryForm
    },
    inventorySupplierIsValid() {
      return this.inventory.customer_id
    },
  },
  methods: {
    onBlurHandler() {
      // event fired when clicking on the input
      if (!this.searchQuery) {
        this.inventory.customer_id = null
      }

      this.isInvalidSupplier = !this.inventory.customer_id
    },
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'product-search',
        placeholder: 'Search...',
        class: 'form-control',
        name: 'product-search',
      }
      return {
        ...defaultInputProps,
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item.name
    },

    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.name
    },
    fetchResults(searchQuery) {
      this.search(searchQuery, this)
    },
    selectHandler(suggest) {
      this.inventory.customer_id = suggest.item
      this.isInvalidSupplier = false
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 50),

    getSelectOptions(params) {
      this.suggestions = []
      if (params && params.length > 2) {
        this.$store.dispatch(`${this.MODULE_NAME}/getSuppliers`, {
          search: params,
          f: {
            allow_to_purchase_from_supplier: '1',
          },
          per_page: 50,
        }).then(({ data }) => {
          const mappedData = data.data.data.map(item => ({
            ...item,
            name: item.cust_name_dba ?? '---',
          }))
          this.suggestions = [{ data: mappedData }]
        })
      }
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'

    return {
      MODULE_NAME,
    }
  },
}
</script>
