<template>
  <div>
    <portal to="body-top">
      <h4 class="font-medium-4 text-center font-weight-bolder mb-1">
        {{ $t('Manually Add Inventory') }}{{ ` — ${inventoryItem.sku} — ${inventoryItem.name}` }}
      </h4>
    </portal>
    <inventory-form
      ref="inventory-form"
      :is-create-form="true"
    />
  </div>
</template>

<script>
import InventoryForm from '../components/InventoryForm.vue'
import config from '../../inventoryConfig'

export default {
  name: 'InventoryCreate',
  components: {
    InventoryForm,
  },

  computed: {
    inventoryItem() {
      return this.$store.state[this.MODULE_NAME].product
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['inventory-form']) {
      this.$refs['inventory-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    onLoad() {
      const defaultData = {
        locations: [],
        amount_to_add: [
          {
            ...this.blankLocation,
          },
        ],
        allowed_to_sell: false,
        inspected: false,
        status: false,
        tested: false,
        customer_id: null,
        date_of_purchase: Date.now(),
        available_start_date: Date.now(),
        active: false,
      }

      this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_FORM`, { ...defaultData })
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_INVENTORY_FORM_CLONE`, { ...defaultData })
    },
  },
  setup() {
    const { blankLocation } = config()
    const MODULE_NAME = 'inventories'
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME_CLONE,
      MODULE_NAME,
      blankLocation,
    }
  },
}
</script>
