<template>
  <div
    style="border-radius: 5px; padding: 12px"
    class="bg-white"
  >
    <b-row>
      <b-col
        cols="6"
      >
        <div class="mb-1">
          <inventory-supplier-name />
        </div>
        <component
          :is="fields['purchase_order_number'].type"
          v-model="inventory['purchase_order_number']"
          v-bind="getProps('purchase_order_number')"
        />
        <component
          :is="fields[field].type"
          v-for="field in ['cost']"
          :key="field"
          v-model="inventory[field]"
          v-bind="getProps(field)"
        />
        <component
          :is="fields[field].type"
          v-for="field in ['sale_price']"
          :key="field"
          v-model="inventory[field]"
          v-bind="getProps(field)"
          :is-required-field="isRequired"
        />
        <vue-tree-select />
        <span class="font-weight-bolder ml-1"> {{ $t('Status') }} </span>
        <component
          :is="fields[field].type"
          v-for="field in ['active', 'inspected']"
          :key="field"
          v-model="inventory[field]"
          v-bind="getProps(field)"
          class="mb-0 custom--inventory-checkbox inventory-checkbox-group"
        />
        <span class="font-weight-bolder ml-1"> {{ $t('Sales') }} </span>
        <component
          :is="fields[field].type"
          v-for="field in ['allowed_to_sell']"
          :key="field"
          v-model="inventory[field]"
          v-bind="getProps(field)"
          class="mb-1 custom--inventory-checkbox inventory-checkbox-group"
          :is-editable="isProductDetailCanBeSold"
          @change="setRequiredSalePriceOnAllowToSellIsChecked($event)"
          @input="allowedToSell"
        />
        <l-counter-input
          :field="{ label: 'Amount to Add', options: { readonly: true }, rules: 'required|integer|positive' }"
          name="l-counter"
          form-name="inventoryForm"
          module-name="inventories"
        />
      </b-col>
      <b-col
        cols="6"
      >
        <component
          :is="fields[field].type"
          v-for="field in ['date_of_purchase', 'invoice_number', 'available_start_date']"
          :key="field"
          v-model="inventory[field]"
          v-bind="getProps(field)"
          @keyup="onKeyUp($event, field)"
        />
        <component
          :is="fields['tags'].type"
          v-if="hasKey('tags')"
          ref="tags_ref"
          v-model="inventory['tags']"
          v-bind="getProps('tags')"
          :selected-values="returnTags('tags')"
        />
        <component
          :is="fields['comments'].type"
          v-model="inventory['comments']"
          v-bind="getProps('comments')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Libs
import { ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import config from '@/views/main/inventory/inventoryConfig'

// Components
import { BRow, BCol } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import VueTreeSelect from './VueTreeSelect.vue'
import InventoryLocations from './InventoryLocations--dont-delete.vue'
import InventorySupplierName from './InventorySupplierName.vue'
import LCounterInput from './LCounterInput.vue'

export default {
  name: 'InventoryNSFormBody',
  components: {
    LCounterInput,
    BCol,
    BRow,
    VueTreeSelect,
    InventoryLocations,
    ValidationObserver,
    InventorySupplierName,
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventoryForm
    },
    isProductDetailCanBeSold() {
      return !this.product?.detail?.can_be_sold
    },
    product() {
      return this.$store.state[this.MODULE_NAME].product
    },
    getStockProduct() {
      return this.$store.state[this.MODULE_NAME].inventory
    },
  },
  methods: {
    allowedToSell() {
      if (this.inventory.allowed_to_sell) {
        this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_FORM`, { ...this.inventory, ...{ active: true } })
      }
    },
    onKeyUp(e, field) {
      if (!e.length) {
        this.inventory[field] = ''
      }
    },
    getProps(fieldName) {
      return {
        field: this.fields[fieldName],
        name: fieldName,
        class: this.fields[fieldName]?.class,
      }
    },
    hasKey(key) {
      // eslint-disable-next-line no-prototype-builtins
      return this.fields.hasOwnProperty(key)
    },
    returnTags(tagsArrName) {
      return this.getStockProduct[tagsArrName]?.map(tag => tag?.label)
    },
    setRequiredSalePriceOnAllowToSellIsChecked(event) {
      if (event) {
        this.fields.sale_price.rules = 'required'
      } else {
        this.fields.sale_price.rules = null
      }
      this.isRequired = event
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const isRequired = ref(false)

    const { fields } = config()
    return {
      fields,
      MODULE_NAME,
      isRequired,
    }
  },
}
</script>
<style lang="scss">
.custom--inventory-checkbox {
  .checkboxGroup {
    padding-left: 0;
  }
}
.inventory-checkbox-group{
  padding: 0 0 0 16px;
}
</style>
