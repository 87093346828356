<template>
  <div>
    <b-row class="align-items-center mb-2">
      <b-col cols="3">
        <span class="font-weight-bolder font-medium-5">{{ $t('Amount to Add') }}</span>
      </b-col>
      <b-col
        cols="3"
        class="align-items-center d-flex"
      >
        <b-button
          :disabled="isDecreaseBtnDisable"
          class="btn-mini"
          @click="removeLastLocation"
        >
          <feather-icon
            icon="LMinusIcon"
            size="16"
          />
        </b-button>
        <l-text-input
          v-model="inventory.locations.length"
          class="inventory__amount-of-location-input mx-1 mb-0"
          name="amountOfLocations"
          :field="{ options: { readonly: true }, rules: 'required|integer|positive' }"
        />
        <b-button
          class="btn-mini"
          variant="success"
          @click="addLocation"
        >
          <feather-icon
            icon="LPlusPureIcon"
            size="16"
            style="stroke:white;"
          />
        </b-button>
      </b-col>
    </b-row>
    <b-table-simple
      v-if="inventory.locations.length"
      small
    >
      <b-thead class="table-simple-thead-inventory-locations">
        <b-tr>
          <b-th
            style="width: 15%"
            class="font-weight-bolder"
          >
            {{ $t('Location') }}
          </b-th>
          <b-th
            style="width: 30%"
            class="font-weight-bold"
          >
            {{ $t('Serial Number') }}
          </b-th>
          <b-th
            style="width: 13%"
            class="font-weight-bolder"
          >
            {{ $t('Asset Number') }}
          </b-th>
          <b-th
            style="width: 10%"
            class="font-weight-bolder"
          >
            {{ $t('Cost') }}
          </b-th>
          <b-th
            style="width: 13%"
            class="font-weight-bolder"
          >
            {{ $t('Available Start Date') }}
          </b-th>
          <b-th class="font-weight-bolder" />
          <b-th class="font-weight-bolder" />
        </b-tr>
      </b-thead>
      <b-tbody class="table-simple-body-inventory-locations">
        <b-tr
          v-for="(item, index) in inventory.locations"
          :key="index"
        >
          <b-td class="table-simple-cell-border-none mb-1">
            <treeselect
              v-model="item.location"
              :options="inventoryWarehouseLocations"
            >
              <div
                slot="value-label"
                slot-scope="{ node }"
              >
                {{ node.raw.optionLabel }}
              </div>
            </treeselect>
          </b-td>
          <b-td
            v-for="locationField in ['serial_number', 'asset_number', 'cost','available_from']"
            :key="locationField"
            class="table-simple-cell-border-none mb-1"
          >
            <component
              :is="locationFields[locationField].type"
              v-model="item[locationField]"
              class="mb-0"
              v-bind="getPropsLocationForm(locationField)"
            />
          </b-td>
          <b-td
            v-for="locationField in ['inspected', 'tested']"
            :key="locationField"
            class="table-simple-cell-border-none mb-1"
          >
            <component
              :is="locationFields[locationField].type"
              v-model="item[locationField]"
              v-bind="getPropsLocationFormCheckbox(locationField, index)"
              class="mb-0 table-simple-cell-checkbox"
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import config from '@/views/main/inventory/inventoryConfig'
import {
  BButton, BRow, BCol,
  BTableSimple, BThead, BTr, BTh, BTbody, BTd,
} from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'InventoryLocations',
  components: {
    BRow,
    BCol,
    BTr,
    BTh,
    BTd,
    BThead,
    BTbody,
    BButton,
    BTableSimple,
    Treeselect,
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventoryForm
    },
    inventoryWarehouseLocations() {
      return this.$store.state[this.MODULE_NAME].warehouses?.locations
    },
    isDecreaseBtnDisable() {
      return this.inventory.locations.length <= 0
    },
  },
  methods: {
    removeLastLocation() {
      this.inventory.locations.pop()
    },
    addLocation() {
      this.inventory.locations.push(JSON.parse(JSON.stringify(this.blankLocation)))
    },
    getProps(fieldName) {
      return {
        field: this.fields[fieldName],
        name: fieldName,
        class: this.fields[fieldName]?.class,
      }
    },
    getPropsLocationForm(fieldName) {
      return {
        field: this.locationFields[fieldName],
        name: fieldName,
        class: this.locationFields[fieldName]?.class,
      }
    },
    getPropsLocationFormCheckbox(fieldName, idx) {
      return {
        field: this.locationFields[fieldName],
        name: fieldName + idx,
        class: this.locationFields[fieldName]?.class,
      }
    },
    generateOptionLabels() {
      if (!this.inventoryWarehouseLocations) return
      // eslint-disable-next-line no-unused-vars
      const fun = (node, label) => {
        console.log(node)
        // eslint-disable-next-line no-param-reassign
        node.optionLabel = label.join('-')
        if (node.children.length) {
          node.children.forEach(item => {
            fun(item, label.concat(item.label))
          })
        }
      }
      this.inventoryWarehouseLocations.forEach(item => {
        console.log(item)
        fun(item, [item.label])
      })
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const { fields, blankLocation, locationFields } = config()
    return {
      MODULE_NAME,
      fields,
      locationFields,
      blankLocation,
    }
  },
}
</script>

<style lang="scss">
.inventory{
  &__amount-of-location-input{
    width: 57px;

    input{
      text-align: center;
    }
  }
}
.bg-date {
  .form-control.flatpickr-input {
    background-color: white !important;
  }
}
.table-simple-cell-border-none {
  border: none !important;
  padding: 0 6px !important;
}

.table-simple-body-inventory-locations {
  & tr {
    height: 50px;
  }
  & tr td:first-child {
    padding-left: 0 !important;
  }
  & tr td:last-child {
    padding-right: 0 !important;
  }
}

.table-simple-thead-inventory-locations {
  & tr th {
    padding: 0 6px !important;
  }
}

.table-simple-cell-checkbox {
  margin-bottom: 4px !important;
  & .checkboxLabel {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 2px;
  }
}
</style>
