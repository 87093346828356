<template>
  <div>
    <div
      class="bg-white mb-1"
      style="border-radius: 5px; padding: 12px"
    >
      <b-row>
        <b-col
          cols="6"
        >
          <div class="mb-1">
            <inventory-supplier-name />
          </div>
          <component
            :is="fieldsNonSerialized['purchase_order_number'].type"
            v-model="inventory['purchase_order_number']"
            v-bind="getProps('purchase_order_number')"
          />
          <vue-tree-select />
          <component
            :is="fieldsNonSerialized['cost'].type"
            v-model="inventory['cost']"
            v-bind="getProps('cost')"
          />
          <div class="mb-2 ml-2">
            <span class="font-weight-bolder ml-1"> {{ $t('Status') }} </span>
            <component
              :is="fields[field].type"
              v-for="field in ['active', 'inspected']"
              :key="field"
              v-model="inventory[field]"
              v-bind="getProps(field)"
              class="mb-0 custom--inventory-checkbox"
            />
          </div>

          <l-counter-input
            :field="{ label: 'Amount to Add', options: { readonly: true }, rules: 'required|integer|positive' }"
            name="l-counter"
            form-name="inventoryForm"
            module-name="inventories"
          />
        </b-col>
        <b-col
          cols="6"
        >
          <component
            :is="fieldsNonSerialized[field].type"
            v-for="field in ['date_of_purchase', 'invoice_number','available_start_date']"
            :key="field"
            v-model="inventory[field]"
            v-bind="getProps(field)"
            @keyup="onKeyUp($event, field)"
          />
          <component
            :is="fields['tags'].type"
            v-if="hasKey('tags')"
            ref="tags_ref"
            v-model="inventory['tags']"
            v-bind="getProps('tags','Type...')"
            :selected-values="returnTags('tags')"
          />
          <component
            :is="fields['comments'].type"
            v-model="inventory['comments']"
            v-bind="getProps('comments','Write notes here...')"
            class="commits"
          />
        </b-col>
      </b-row>
    </div>
    <render-amounts v-if="inventory.amount_to_add.length" />
  </div>
</template>

<script>
// Libs
import { ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import config from '@/views/main/inventory/inventoryConfig'

// Components
import { BRow, BCol } from 'bootstrap-vue'
import VueTreeSelect from './VueTreeSelect.vue'
import InventoryLocations from './InventoryLocations--dont-delete.vue'
import InventorySupplierName from './InventorySupplierName.vue'
import LCounterInput from './LCounterInput.vue'
import RenderAmounts from './RenderAmounts.vue'

export default {
  name: 'InventorySFormBody',
  components: {
    LCounterInput,
    BCol,
    BRow,
    VueTreeSelect,
    InventoryLocations,
    ValidationObserver,
    InventorySupplierName,
    RenderAmounts,
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].inventoryForm
    },
    getStockProduct() {
      return this.$store.state[this.MODULE_NAME].inventory
    },
  },
  methods: {
    onKeyUp(e, field) {
      if (!e.length) {
        this.inventory[field] = ''
      }
    },
    getProps(fieldName, placeholderName) {
      return {
        field: this.fieldsNonSerialized[fieldName],
        name: fieldName,
        placeholder: placeholderName,
        class: this.fieldsNonSerialized[fieldName]?.class,
      }
    },
    hasKey(key) {
      // eslint-disable-next-line no-prototype-builtins
      return this.fieldsNonSerialized.hasOwnProperty(key)
    },
    returnTags(tagsArrName) {
      return this.getStockProduct[tagsArrName]?.map(tag => tag?.label)
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'

    const { fields, fieldsNonSerialized } = config()
    return {
      fields,
      MODULE_NAME,
      fieldsNonSerialized,
    }
  },
}
</script>
<style lang="scss">
.custom--inventory-checkbox {
  .checkboxGroup {
    padding-left: 0;
  }
}

.vue-treeselect__control-arrow-container>svg{
  width: 20px;
  height: 20px;
  fill: none;
  background-image: url('../../../../../assets/images/svg/select.svg');
  background-repeat: no-repeat;
  background-position: center;
}
//.vue-treeselect__menu>div>div>div>div>div{
//  border: 1px solid red;
//  fill: none;
//}
</style>
